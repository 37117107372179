import { Subscription, timer } from 'rxjs';
import { DateTime, Settings } from 'luxon';
import { Component, OnInit, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '@services/auth.service';
import { RouterOutlet } from '@angular/router';
import * as config from '@configs/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
  private authService: AuthService = inject(AuthService);
  private cookieService: CookieService = inject(CookieService);

  title = 'app';
  subscriptions = new Subscription();
  checkInterval = 60000;
  check$ = timer(0, this.checkInterval);

  constructor() {
    Settings.throwOnInvalid = true;
    const user = localStorage.getItem('user');
    if (user) {
      const { timezone } = JSON.parse(`${user}`);
      Settings.defaultZone = timezone ?? null;
    }
  }

  ngOnInit(): void {
    this.startCheckAuthOnInterval();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  startCheckAuthOnInterval() {
    const subscription = this.check$.subscribe(() => {
      const expiry = this.cookieService.get('expiry');
      // no expiry cookie = no refresh token = no qualified to stay signin
      if (!expiry) { return; }

      // console.log(`🔥 access_token_expiry.diffNow()`, DateTime.fromMillis(+expiry).diffNow().as('milliseconds'));
      if (DateTime.fromMillis(+expiry).diffNow().as('milliseconds') < this.checkInterval) {
        // access_token was expired(< 0) OR will expires soon(< checkInterval) within next cycle
        // then request new access_token via refresh token
        return this.authService.refreshToken().subscribe();
      }

      // staying signin, server ping check for duplicate login, permission changed, etc.
      return this.authService.check().subscribe();
    });
    this.subscriptions.add(subscription);
  }

}