import { Routes } from '@angular/router';
import { StandardLayoutComponent } from '@components/layouts/standard/standard.component';
import HomeComponent from '@components/pages/home.component';
import PageNotFoundComponent from '@components/pages/page-not-found.component';
import authGuard from '@guards/auth.guard';
import permissionGuard from '@guards/permission.guard';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/signin' },
  {
    path: '', loadChildren: () => import('./modules/auth/auth.routes')
  },
  {
    path: '',
    component: StandardLayoutComponent,
    canActivate: [authGuard],
    children: [
      { path: 'home', component: HomeComponent, title: 'Home' },
    ]
  },
  {
    path: '', loadChildren: () => import('./modules/main/main.routes')
  },
  {
    path: '**', component: PageNotFoundComponent
  }
];