import { Injectable } from '@angular/core';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class AppTitleStrategy extends TitleStrategy {
  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      document.title = `${title} - ${environment.name}`;
    } else {
      document.title = `${environment.name}`;
    }
  }
} 