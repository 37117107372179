import _ from 'lodash-es';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: [],
  standalone: true,
  imports: [RouterLink]
})
export default class PageNotFoundComponent implements OnInit {
  constructor(
    private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle(`Page Not Found`);
  }
}

export { PageNotFoundComponent };
