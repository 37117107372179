import _ from 'lodash-es';
import { Component, OnInit } from '@angular/core';
import { AuthorizeDirective } from '@directives/authorize.directive';
import DatePickerComponent from '../common/date-picker/date-picker.component';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.scss'],
  standalone: true,
  imports: [AuthorizeDirective, DatePickerComponent]
})
export default class HomeComponent implements OnInit {
  dateTimeInit = DateTime.local();
  constructor() {
    // inject(Title).setTitle(`Home - ${inject(Title).getTitle()}`);
  }

  ngOnInit() { }

  onDatePicked(e: DateTime) {
    console.log(e.toFormat('yyyy-LL-dd HH:mm:ss'));
  }
}

export { HomeComponent };
