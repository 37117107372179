import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import forge from 'node-forge';
@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private cookieService: CookieService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 401:
                const unauthorized = localStorage.getItem("unauthorized");
                const user = localStorage.getItem("user");
                localStorage.clear();
                console.log('__Unauthorized', this.router.url);
                if (this.router.url.includes('/signin') || unauthorized) { return this.router.navigate(["/signin"]); }
                if (!unauthorized) { localStorage.setItem('unauthorized', 'true'); }
                let id = '';
                if (user) {
                  const md5 = forge.md.md5.create();
                  md5.update(`${JSON.parse(user || "")?.user_id}`);
                  id = md5.digest().toHex();
                }
                return this.router.navigate(['/signin'], { queryParams: { return: this.router.url, id } });
            }
          }
          return;
        })
    );
  }
}
