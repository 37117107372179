import { CookieService } from 'ngx-cookie-service';
import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { TitleStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AppTitleStrategy } from './title-strategy';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    CookieService,
    { provide: TitleStrategy, useClass: AppTitleStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
};
