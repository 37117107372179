import { DateTime, Settings } from 'luxon';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let token = event.headers.get('Authorization');
          token = token && token.replace(/^Bearer /, '');
          if (!token) { return; }

          // read token
          const helper = new JwtHelperService();
          const { user } = helper.decodeToken(token);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('token', token);

          // set timezone
          Settings.defaultZone = user.timezone ?? null;
        }

        return;
      })
    );


  }
}
