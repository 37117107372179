<section class="bg-white dark:bg-gray-900">
  <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
    <div class="max-w-screen-sm mx-auto text-center">
      <h1 class="mb-4 font-extrabold tracking-tight text-7xl lg:text-9xl text-primary-600 dark:text-primary-500">
        <i class="fa-sharp fa-solid fa-door-open"></i>
      </h1>
      <p class="mb-4 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl dark:text-white"></p>
      <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400"></p>
    </div>
  </div>
</section>